import api from "@/base/utils/request";

// 获取品牌列表
export const brandList = data => {
  return api({
    url: "/admin/cyc/multi_level/index",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc/multi_level/updateSort",
    method: "post",
    data
  });
};

// 更新推荐
export const updateRecommend = data => {
  return api({
    url: "/admin/cyc/multi_level/updateRecommend",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc/multi_level/updateShow",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = id => {
  return api({
    url: "/admin/cyc/multi_level/softDelete",
    method: "post",
    data: {id}
  });
};

// 回收站永久删除
export const Delete = id => {
  return api({
    url: "/admin/cyc/multi_level/del",
    method: "post",
    data: {id}
  });
};

// 回收站还原数据
export const putBack = id => {
  return api({
    url: "/admin/cyc/multi_level/putBack",
    method: "post",
    data: {id}
  });
};

// 获取文章/活动列表
export const postedList = data => {
  return api({
    url: "/admin/cyc/multi_level/posted",
    method: "post",
    data
  });
};

// 获取已选择文章/活动列表
export const HaveChosenList = data => {
  return api({
    url: "/admin/cyc/multi_level/HaveChosen",
    method: "post",
    data
  });
};

// 添加文章/活动
export const AddList = data => {
  return api({
    url: "/admin/cyc/multi_level/add",
    method: "post",
    data
  });
};

// 删除文章/活动
export const DeleteList = data => {
  return api({
    url: "/admin/cyc/multi_level/deleteBrand",
    method: "post",
    data
  });
};

// 获取所有来源
export const AllSource = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};
